/* Styles from design */

  /* Primary Buttons / Primær knapper */

  /* Blue primary */
  .buttonPrimaryBlue {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #9EB1D9; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: white; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }
  
  .buttonPrimaryBlue:hover {
    background-color: #8A9ABF; /* For en middels grå */
  }
  
  .buttonPrimaryBlue:focus-visible {
    outline: 2px solid #8A9ABF; 
    outline-offset: 2px;
  } 

  /* Green primary */
  .buttonPrimaryGreen {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #84E7CF; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: #3B4058; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }
  
  .buttonPrimaryGreen:hover {
    background-color: #75C5B7; /* For en middels grå */
  }
  
  .buttonPrimaryGreen:focus-visible {
    outline: 2px solid #75C5B7; 
    outline-offset: 2px;
  } 


  /*  Secondary Buttons / Sekundær Knapper */

  /* Pink secondary */
  .buttonSecondaryPink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #EF9A84; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: #3B4058; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }
  
  .buttonSecondaryPink:hover {
    background-color: #CB887B; 
  }

  .buttonSecondaryPink:focus-visible {
    outline: 2px solid #CB887B; 
    outline-offset: 2px;
  }

 
  /* Green secondary */
  .buttonSecondaryGreen {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #227E7B; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: white; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }

  .buttonSecondaryGreen:hover {
    background-color: #277274; /* For en middels grå */
  }

  .buttonSecondaryGreen:focus-visible {
    outline: 2px solid #277274; 
    outline-offset: 2px;
  } 

  /* Gray ternary */
  .buttonTernaryGray {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #3B4058; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: white; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }
  /*Old hover: 2f3346 */
  .buttonTernaryGray:hover {
    background-color: #2f3346; /* For en middels grå */
  }

  .buttonTernaryGray:focus-visible {
    outline: 2px solid #2f3346; 
    outline-offset: 2px;
  } 

  /* White ternary */
  .buttonTernaryWhite {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #ffffff; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: #3B4058; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }

  .buttonTernaryWhite:hover {
    background-color: #c9c9c9; /* For en middels grå */
  }

  .buttonTernaryWhite:focus-visible {
    outline: 2px solid #c9c9c9; 
    outline-offset: 2px;
  } 

  /* White ternary */
.buttonTernaryWhiteBorder {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
  border-radius: 0.375rem; /* rounded-md */
  background-color: #ffffff; /* bg-[#257E7B] */
  padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
  font-size: 0.875rem; /* text-sm */
  font-weight: 600; /* font-semibold */
  color: #3B4058; /* text-white */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
  transition: background-color 0.3s; /* For en jevn overgang på hover */
  border: 1px solid #ccc; /* Grå kant */
}

.buttonTernaryWhiteBorder:hover {
  background-color: #c9c9c9; /* For en middels grå */
}

.buttonTernaryWhiteBorder:focus-visible {
  outline: 2px solid #c9c9c9;
  outline-offset: 2px;
}

  /* Red Warning button */
  .buttonWarningRed{
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; /* gap-x-2 tilsvarer ca 0.5rem avhengig av ditt designsystem */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #d60101; /* bg-[#257E7B] */
    padding: 0.625rem 0.875rem; /* px-3.5 py-2.5 */
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: #ffffff; /* text-white */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
    transition: background-color 0.3s; /* For en jevn overgang på hover */
  }
  
  .buttonWarningRed:hover {
    background-color: #b90202; /* For en middels grå */
  }
  
  .buttonWarningRed:focus-visible {
    outline: 2px solid #b90202;
    outline-offset: 2px;
  }



  /* Main page */

  .mainPage {
    margin-left: 122px;
  }