.message-sent {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 100px;
  }
  
  .message-received {
    margin-right: 100px;
  }

  .message-sent-color {
    background-color: #9EB1D9;
}

.message-received-color {
    background-color: #F5DFCC;
}


html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }



  .form-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  
  .form-bottom {
    margin-top: auto; /* Dytter skjemaet til bunnen */
  }