@import 'styles/tailwind.css';
@tailwind components;
@tailwind utilities;

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse:hover {
  animation: pulse 1s infinite;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  transition: width 0.5s ease-in-out;
}


.squish-button:active {
  transform: scaleY(0.9); /* Skviser knappen vertikalt ved klikk */
  transition: transform 0.1s; /* Glatte ut animasjonen */
}